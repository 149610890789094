import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState, } from 'react';
import { InputAdornment, Slider } from '@mui/material';
import Input from '@shared/ui/inputs/Input';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { ActiveText, Body, Subtitle } from '@components/styled/Typography';
import { debounceWrapper } from '@utils/Helpers';
const Container = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const SliderInputContainer = styled(ContainerColumn)(() => ({
    position: 'relative',
    height: 'auto',
}));
const InputsContainer = styled.div((props) => ({
    display: 'flex',
    marginBottom: `${props.theme.spacing_sizes.m}px`,
    gap: `${props.theme.spacing_sizes.xs * 2}px`,
}));
const SliderStyled = styled(Slider)(props => ({
    borderTopLeftRadius: 0,
    backgroundColor: `${props.theme.customColors.input.border}`,
    margin: `${props.theme.spacing_sizes.xs}px 0`,
    borderRadius: `${props.theme.spacing_sizes.xs / 2}px`,
    padding: 0,
    '& .MuiSlider-rail': {
        display: 'none',
    },
    '& .MuiSlider-thumb': {
        width: `${props.theme.spacing_sizes.xs * 2}px`,
        height: `${props.theme.spacing_sizes.xs * 2}px`,
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxSizing: 'content-box',
            border: '3px solid #D8E0F8',
            boxShadow: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        '&:before': {
            display: 'none',
        },
        background: 'black',
        padding: '3px 6px',
        borderRadius: `${props.theme.spacing_sizes.s}px`,
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(0%, -70%)',
            '&:before': {
                display: 'none',
            },
        },
    },
}));
const MarksContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    justifyContent: 'space-between',
    marginTop: `${props.theme.spacing_sizes.xs}px`,
}));
const Mark = styled(ActiveText)(props => ({
    fontWeight: 400,
    color: `${props.theme.palette.text.secondary}`,
}));
const SliderInputAdornment = styled(Subtitle)(() => ({
    fontWeight: 400,
}));
const SliderLabel = styled(Body)((props) => ({
    fontWeight: 500,
    marginBottom: `${props.theme.spacing_sizes.xs * 1.25}px`,
}));
const ValidateInputDebounceDelay = 500;
const RangeSliderInput = ({ value, onChange, onInputChange, minMaxValues, label = undefined, minInputPlaceholder = undefined, maxInputPlaceholder = undefined, }) => {
    const [currentValue, setCurrentValue] = useState(value);
    useEffect(() => {
        setCurrentValue(value);
    }, [value]);
    const validateInput = useCallback(([newValue, position]) => {
        const [min, max] = minMaxValues;
        let result = newValue;
        if (result < min) {
            result = min;
        }
        else if (newValue > max) {
            result = max;
        }
        setCurrentValue((prev) => {
            if (position === 'start')
                return [result, prev[1]];
            return [prev[0], result];
        });
        onInputChange(result, position);
    }, [minMaxValues, setCurrentValue, onChange]);
    const validateInputDebounce = useMemo(() => debounceWrapper(validateInput, ValidateInputDebounceDelay), [validateInput]);
    const onChangeHandler = (position) => (event) => {
        const newValue = Number(event.target.value) || 0;
        setCurrentValue((prev) => {
            if (position === 'start')
                return [newValue, prev[1]];
            return [prev[0], newValue];
        });
        validateInputDebounce([newValue, position]);
    };
    const onSliderChange = (event, newValue) => {
        if (Array.isArray(newValue) && newValue.length === 2) {
            onChange(newValue);
        }
    };
    return (_jsxs(Container, { children: [label ? _jsx(SliderLabel, { children: label }) : null, _jsxs(SliderInputContainer, { children: [_jsxs(InputsContainer, { children: [_jsx(Input, { size: 'medium', value: currentValue[0], onChange: onChangeHandler('start'), startAdornment: minInputPlaceholder ? (_jsx(InputAdornment, { position: 'start', children: _jsx(SliderInputAdornment, { children: minInputPlaceholder }) })) : null }), _jsx(Input, { size: 'medium', value: currentValue[1], onChange: onChangeHandler('end'), startAdornment: maxInputPlaceholder ? (_jsx(InputAdornment, { position: 'start', children: _jsx(SliderInputAdornment, { children: maxInputPlaceholder }) })) : null })] }), _jsx(SliderStyled, { size: 'medium', min: minMaxValues[0], max: minMaxValues[1], value: value, onChange: onSliderChange, valueLabelDisplay: 'auto' })] }), _jsxs(MarksContainer, { children: [_jsx(Mark, { children: minMaxValues[0] }), _jsx(Mark, { children: minMaxValues[1] })] })] }));
};
export default RangeSliderInput;
